<div [id]="id">
    <div class="control-input__day">
        <input #day type="tel" class="form-control" size="2" name="day" maxlength="2"
            (input)="onInputChange('day')"
            (keydown.backspace)="backspace($event)" (focus)="_onFocus()" (blur)="_onBlur()"
            [disabled]="disabled">
    </div>
    <div class="control-input__month">
        <input #month type="tel" class="form-control" size="2" name="month" maxlength="2"
            (input)="onInputChange('month')"
            (keydown.backspace)="backspace($event)" (focus)="_onFocus()" (blur)="_onBlur()"
            [disabled]="disabled">
    </div>
    <div class="control-input__year">
        <input #year type="tel" class="form-control" size="4" name="year" maxlength="4"
            (input)="onInputChange('year')"
            (keydown.backspace)="backspace($event)" (focus)="_onFocus()" (blur)="_onBlur()"
            [disabled]="disabled">
    </div>
</div>
