import {
    AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, Input, OnInit, Optional, Self, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { ErrorStateMatcher } from '../core/error-options';
import { mixinErrorState } from '../core/error-state';

class CynoRadioBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
    ) {}
}

const _CynoRadioMixinBase = mixinErrorState(CynoRadioBase);

class CynoRadio {}

@Component({
    selector: 'cyno-radio',
    template: `<input type="radio" [ngClass]="{'bordered': bordered}" [name]="ngControl.name" [value]="value" [id]="id" [checked]="checked"
                (change)="onInputChange($event)" (click)="onInputClick($event)"><label [for]="id"><ng-content></ng-content></label>`,
    providers: [{ provide: CynoFieldControl, useExisting: CynoRadioInputComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./cyno-radio.component.scss'],
})

export class CynoRadioInputComponent
    extends _CynoRadioMixinBase
    implements ControlValueAccessor, CynoFieldControl<CynoRadio>, AfterContentInit, DoCheck, OnInit {

    @Input() get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input() get required() {
        return this._required;
    }
    set required(req) {
        this._required = req;
        this.stateChanges.next();
    }
    @Input() get disabled() {
        return this._disabled;
    }
    set disabled(isDisabled: boolean) {
        this._disabled = isDisabled;
        this.stateChanges.next();
    }

    @Input() get checked() {
        return this._checked;
    }

    set checked(isChecked: boolean) {
        this._checked = isChecked;
        this.stateChanges.next();
    }
    protected _disabled: boolean = false;

    static nextId = 0;
    private _checked: boolean = false;
    private _currentValue: string | number;
    private _placeholder: string;
    private _required = false;
    @ViewChild('initials', { static: false }) initials: ElementRef;

    public id: string;
    public focused: boolean = false;

    @Input() value: string | number;
    @Input() bordered: boolean = false;

    public stateChanges: Subject<void> = new Subject();

    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        this.ngControl.valueChanges.subscribe((value) => {
            this.checked = value === this.value;
            this.changeDetectorRef.markForCheck();
        });
    }

    ngAfterContentInit(): void {
        this._onTouched();
        this.id = `${this.ngControl.name}-${++CynoRadioInputComponent.nextId}-${this.value}`;

        if (this.value === this._currentValue) {
            this.checked = true;
        }  else {
            this.checked = false;
        }
        this.changeDetectorRef.markForCheck();
        this.ngControl.control.markAsUntouched({onlySelf: true});
    }

    public ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public writeValue(currentValue: string | number): void {
        this.checked = this.value === currentValue;

        this._currentValue = currentValue;

        if (currentValue) {
            this._onTouched();
        }

        this.changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    public onInputChange(event): void {
        this.checked = this.value === event.target.value;

        this._onChange(event.target.value);

        this.changeDetectorRef.markForCheck();
        this._onTouched();
    }

    public onInputClick(event): void {
        event.stopPropagation();
    }

    public onContainerClick(): void {

    }

    private _onChange: (value: any) => void = () => {};
    private _onTouched = () => {};
}
