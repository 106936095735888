import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';

import { CynoFieldControl } from '../core/cyno-field-control';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';
import { ErrorStateMatcher } from '../core/error-options';
import { DateService, DateStruct } from 'outshared-lib';


class CynoDateBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl
    ) { }
}

const _CynoDateMixinBase = mixinErrorState(CynoDateBase);

class CynoDate {
    constructor(public day: number = null, public month: number = null, public year: number = null) {
    }
}

@Component({
    selector: 'cyno-date',
    templateUrl: `./cyno-date.component.html`,
    styleUrls: [`./cyno-date.component.scss`],
    providers: [
        {provide: CynoFieldControl, useExisting: CynoDateInputComponent}
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CynoDateInputComponent extends _CynoDateMixinBase
    implements ControlValueAccessor, CynoFieldControl<CynoDate>, OnInit, DoCheck, OnDestroy, CanUpdateErrorState {

    @Input() get value(): CynoDate | null {
        return null;
    }
    set value(date: CynoDate | null) {
        this.stateChanges.next();
    }

    @Input() get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input() get required(): boolean {
        return this._required;
    }
    set required(req) {
        this._required = req;
        this.stateChanges.next();
    }

    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = value;
        this.stateChanges.next();
    }
    protected _disabled: boolean = false;

    static nextId = 0;
    private _placeholder: string;
    private _required = false;

    readonly stateChanges: Subject<void> = new Subject<void>();
    focused: boolean = false;
    errorState: boolean = false;
    controlType: string = 'cyno-date-input';


    @ViewChild('day', { read: ElementRef, static: true }) dayRef: ElementRef;
    @ViewChild('month', { read: ElementRef, static: true }) monthRef: ElementRef;
    @ViewChild('year', { read: ElementRef, static: true }) yearRef: ElementRef;


    id = `cyno-date-input-${CynoDateInputComponent.nextId++}`;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
        private _elementRef: ElementRef,
        private readonly dateService: DateService
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        this.stateChanges.next();
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    focus(): void {
        this._elementRef.nativeElement.focus();
    }

    writeValue(value: any): void {
        if (value) {
            this.parseValue(value);
        } else {
            this.resetValue();
        }
    }

    registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    parseValue(date: string): void {
        if (!this.dateService.isValid(date)) {
            return;
        }

        const parsed = date.split('-');

        this.yearRef.nativeElement.value = parsed[0];
        this.monthRef.nativeElement.value = parsed[1];
        this.dayRef.nativeElement.value = parsed[2];

        this.onInputChange('year');
    }

    resetValue(): void {
        this.yearRef.nativeElement.value = '';
        this.monthRef.nativeElement.value = '';
        this.dayRef.nativeElement.value = '';
    }

    setValue(value: any): void {
        if (value !== null) {
            this._onTouched();
            this._onChange(`${value.year}-${value.month}-${value.day}`);
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
        this.stateChanges.next();
    }

    onContainerClick(event: MouseEvent): void {
        this.focus();
    }

    public onInputChange(name: string): void {
        const day: number = this.dayRef.nativeElement.value || null;
        const month: number = this.monthRef.nativeElement.value || null;
        const year: number = this.yearRef.nativeElement.value || null;

        const ngbDate = {
            year: year,
            month: month,
            day: day
        };
        if (name === 'day' && day > 3) {
            this.focusElement('month');
        }

        if (name === 'month' && month > 1) {
            this.focusElement('year');
        }

        const date = this.getDate(ngbDate);


        if (date !== null) {
            if (date === false) {
                this._onChange(null);
            } else {
                this._onTouched();
                this._onChange(date);
            }
        }
    }

    getDate(date: DateStruct): string | boolean {
        if (!this.dateService.isValid(date)) {
            return false;
        }

        if (this.dateService.isBefore(date, '1000-01-01')) {
            return false;
        }

        if (this.dateService.isAfter(date)) {
            return false;
        }

        return this.dateService.format(date);
    }

    focusElement(name: string): void {
        switch (name) {
            case 'day':
                this.dayRef.nativeElement.focus();
                break;
            case 'month':
                this.monthRef.nativeElement.focus();
                break;
            case 'year':
                this.yearRef.nativeElement.focus();
                break;
        }
    }

    backspace(event: any): void {
        this._onChange(null);

        if (event.target.value === '') {
            switch (event.target.name) {
                case 'month':
                    this.focusElement('day');
                    break;
                case 'year':
                    this.focusElement('month');
                    break;
            }
        }
    }

    public _onFocus(): void {
        this.focused = true;
    }

    public _onBlur(): void {
        this.focused = false;
        setTimeout(() => {
            if (!this.focused) {
                this._onTouched();
            }
        }, 50);
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
    }
    private _onChange: (value: any) => void = () => { };
    private _onTouched = () => { };
}
