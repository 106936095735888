import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CynoSelect } from './cyno-select.component';

@NgModule({
    declarations: [
        CynoSelect,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        CynoSelect
    ],
    providers: [],
})
export class CynoSelectModule {}
