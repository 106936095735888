import {
    Component, Input, OnDestroy, Optional, Self, ElementRef, ChangeDetectorRef,
    OnInit, DoCheck, ChangeDetectionStrategy, ViewChild
} from '@angular/core';
import { NgControl, ControlValueAccessor, FormGroupDirective, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { mixinErrorState, CanUpdateErrorState } from '../core/error-state';
import { ErrorStateMatcher } from '../core/error-options';
import { CynoBirthdateService } from './cyno-birthdate.service';
import { DateService } from 'outshared-lib';

class CynoBirthdateBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl
    ) { }
}

const _CynoBirthdateMixinBase = mixinErrorState(CynoBirthdateBase);

class CynoBirthdate {
    constructor(public birthdate: number = null) { }
}

@Component({
    selector: 'cyno-birthdate',
    templateUrl: `./cyno-birthdate.component.html`,
    providers: [
        { provide: CynoFieldControl, useExisting: CynoBirthdateInputComponent }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CynoBirthdateInputComponent extends _CynoBirthdateMixinBase
    implements ControlValueAccessor, CynoFieldControl<CynoBirthdate>, OnInit, DoCheck, OnDestroy, CanUpdateErrorState {

    @Input() get value(): CynoBirthdate | null {
        return null;
    }
    set value(birthdate: CynoBirthdate | null) {
        this.stateChanges.next();
    }

    @Input() get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input() get required() {
        return this._required;
    }
    set required(req) {
        this._required = req;
        this.stateChanges.next();
    }

    @Input()  get disabled() {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = value;
        this.stateChanges.next();
    }

    protected _disabled: boolean = false;

    static nextId = 0;
    private _placeholder: string;
    private _required = false;

    readonly stateChanges: Subject<void> = new Subject<void>();

    public focused: boolean = false;
    public errorState: boolean = false;
    public controlType: string = 'cyno-date-input';

    @ViewChild('birthdate', { static: true }) birthdate: ElementRef;

    public id = `cyno-date-input-${CynoBirthdateInputComponent.nextId++}`;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
        private _elementRef: ElementRef,
        private dateService: DateService,
        private cynoBirthdateService: CynoBirthdateService
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        this.stateChanges.next();
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    /**
     * This method is called by the forms API to write to the view
     * when programmatic changes from model to view are requested.
     * @param value string
     */
    public writeValue(value: string): void {
        if (value) {
            this.setValue(value);
        } else {
            this.resetValue();
        }
    }

    /**
     * Registers a callback function that is called when
     * the control's value changes in the UI.
     * @param fn function
     */
    public registerOnChange(fn: (value: string) => void): void {
        this._onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on
     * initialization to update the form model on blur.
     * @param fn function
     */
    public registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    /**
     * setDisabledState function - Function that is called by the forms API
     * when the control status changes to or from 'DISABLED'.
     * Depending on the status, it enables or disables the appropriate DOM element.
     * @param isDisabled boolean
     */
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
        this.stateChanges.next();
    }

    /**
     * onContainerClick function - This method will be called when the form field is clicked on
     * @param event MouseEvent
     */
    public onContainerClick(event: MouseEvent): void {
        this.focus();
    }

    /**
     * onInput function - event handler function for input change
     * @param input
     */
    public onInput(input): void {
        const value = this.cynoBirthdateService.formatInput(this.getNativeElementValue());

        if (input.inputType === 'deleteContentBackward') {
            return;
        }

        this.setNativeElementValue(value);
        this._onChange(this.cynoBirthdateService.reverseDate(value));
    }

    /**
     * onBackSpace function - event handler function for backpace key down
     */
    public onBackspace(): void {
        const value = this.cynoBirthdateService.formatBackspace(this.getNativeElementValue());

        this.setNativeElementValue(value);
        this._onChange(this.cynoBirthdateService.reverseDate(value));
    }

    /**
     * onFocus function - event handler
     */
    public onFocus(): void {
        this.focused = true;
    }

    /**
     * onBlur function -  - event handler
     */
    public onBlur(): void {
        this.focused = false;
        setTimeout(() => this._onTouched(), 0);
    }

    /**
     * onPaste function - event handler
     */
    public onPaste(): boolean {
        return false;
    }

    /**
     * callback function that is called when
     * the control's value changes in the UI.
     */
    private _onChange: (value: string) => void = () => {};

    /**
     * callback function that is called by the forms API
     * to update the form model on blur.
     */
    private _onTouched = () => {};

    /**
     * set value - validate and set value.
     * @param value string - YYYY-MM-DD
     */
    private setValue(value: string): void {
        const inValidDate = !this.dateService.isValid(value);

        if (inValidDate) {
            return;
        }

        this.setNativeElementValue(
            this.cynoBirthdateService.formatInitial(value)
        );
    }

    /**
     * resetValue function - resets native element value
     */
    private resetValue(): void {
        this.setNativeElementValue('');
    }

    /**
     * set native element value - sets a value to the native (HTML) element
     * @param value string
     */
    private setNativeElementValue(value: string): void {
        this.birthdate.nativeElement.value = value;
    }

    /**
     * getNativeElementValue function - returns the value of native HTML element
     */
    private getNativeElementValue(): string {
        return this.birthdate.nativeElement.value;
    }

    /**
     * focus function - set focus to native (HTML) element
     */
    private focus(): void {
        this._elementRef.nativeElement.focus();
    }
}
