import { Component, Input } from '@angular/core';

let nextUniqueId = 0;

@Component({
    selector: 'cyno-error',
    host: {
        class: 'cyno-error',
        role: 'alert',
        '[attr.id]': 'id',
    },
    templateUrl: './cyno-error.component.html',
})

export class CynoErrorComponent {
    @Input() id = `cyno-error-${nextUniqueId++}`;
}
