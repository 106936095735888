import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    QueryList,
} from '@angular/core';
import { CynoErrorComponent } from '@app/shared/cyno-form/cyno-error/cyno-error.component';
import { Subscription } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { CynoInfoDirective } from '../cyno-directives/cyno-info.directive';
import { CynoLabelDirective } from '../cyno-directives/cyno-label.directive';

@Component({
    selector: 'cyno-field',
    templateUrl: './cyno-field.component.html',
    host: {
        class: 'form-group',
        '[class.has-error]': 'hasError()',
        '[class.has-success]': 'hasSuccess()',
        '[class.is-pending]': 'isPending()',
    },
    styles: [`
        :host {
            display: block
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class CynoFieldComponent implements AfterContentInit, OnDestroy {

    @ContentChild(CynoFieldControl, {static: true}) control: CynoFieldControl<any>;
    @ContentChild(CynoInfoDirective, {static: true}) info: CynoInfoDirective;
    @ContentChild(CynoLabelDirective, {static: true}) label: CynoLabelDirective;

    @ContentChildren(CynoFieldControl) controls: QueryList<CynoFieldControl<any>>;
    @ContentChildren(CynoErrorComponent) errorChildren: QueryList<CynoErrorComponent>;

    @Input() tooltip: string = '';
    @Input() inline: boolean = true;
    @Input() withButton: boolean = false;
    @Input() showSuccess: boolean = true;
    @Input() dualInput: boolean = false;

    public showInfo: boolean = false;

    private subscriptions = new Subscription();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private _el: ElementRef,
    ) {}

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event): void {
        if (!this.showInfo) {
            return;
        }

        if (!this._el.nativeElement.contains(event.target)) {
            this.showInfo = false;
        }
    }

    public ngAfterContentInit(): void {
        this.checkInputExists();

        this.controls.forEach(
            (control: CynoFieldControl<any>) => {
                if (control.ngControl) {
                    const valueChanges = control.ngControl.valueChanges
                        .subscribe(() => this.changeDetectorRef.markForCheck());

                    const statusChanges = control.ngControl.statusChanges
                        .subscribe(() => this.changeDetectorRef.markForCheck());

                    this.subscriptions.add(valueChanges);
                    this.subscriptions.add(statusChanges);
                }
            },
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public hasError(): boolean {
        return this.controls.filter(
            (control: CynoFieldControl<any>) => control.ngControl.invalid && control.ngControl.touched,
        ).length > 0;
    }

    public hasSuccess(): boolean {
        const controlsAreValid = this.controls.filter(
            (control: CynoFieldControl<any>) => control.ngControl.invalid,
        ).length === 0;
        return this.showSuccess && controlsAreValid;
    }

    public isPending(): boolean {
        return this.controls.filter((control: CynoFieldControl<any>) => control.ngControl.pending).length > 0;
    }

    public hasIconInfo(): boolean {
        if (this.hasInfoDirective()) {
            return this.info.visible;
        }
        return (this.tooltip.length > 0);
    }

    public hasInfoDirective(): boolean {
        return !!this.info;
    }

    public onTooltip(event: Event): boolean {
        if (!this.hasInfoDirective()) {
            return;
        }

        this.showInfo = !this.showInfo;

        event.stopPropagation();

        return false;
    }

    private checkInputExists(): void {
        if (!this.control && this.controls.length === 0) {
            throw new Error('Input is missing from field!');
        }
    }
}
