import { Injectable } from '@angular/core';
import { CountryInterface } from '@app/core/interfaces';
import { RequestService } from 'outshared-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * @deprecated use OutCountriesModule from outshared-lib
 */
@Injectable({
    providedIn: 'root',
})
export class CountryService {
    constructor(private request: RequestService) {}

    public getCountries(): Observable<CountryInterface[]> {
        return this.request.post('en-gb/damage-general/request/countries').pipe(
            map((response) => response.data),
            map((data) => data.country)
        );
    }
}
