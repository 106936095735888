import { ChangeDetectorRef, Directive, DoCheck, ElementRef, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';

import { CynoFieldControl } from '../core/cyno-field-control';
import { CanDisable, mixinDisabled } from '../core/disabled';
import { HasTabIndex, mixinTabIndex } from '../core/tabindex';
import { ErrorStateMatcher } from '../core/error-options';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';

import { CYNO_OPTION_PARENT_COMPONENT } from './cyno-option.directive';

export class CynoSelectBase {
    constructor(public _elementRef: ElementRef,
                public _defaultErrorStateMatcher: ErrorStateMatcher,
                public _parentForm: NgForm,
                public _parentFormGroup: FormGroupDirective,
                public ngControl: NgControl) {}
}
export const _CynoSelectMixinBase = mixinTabIndex(mixinDisabled(mixinErrorState(CynoSelectBase)));

@Directive({
    selector: 'select[cynoSelect]',
    exportAs: 'cynoSelect',
    host: {
        class: 'form-control form-control--dropdown',
        role: 'listbox',
        '[attr.id]': 'id',
        '[attr.tabindex]': 'tabIndex',
        '[attr.aria-required]': 'required.toString()',
        '[attr.aria-disabled]': 'disabled.toString()',
        '[attr.aria-invalid]': 'errorState',
        '[attr.aria-multiselectable]': 'multiple',
        '[disabled]': 'disabled',
        '[class.cyno-select-invalid]': 'errorState',
        '[class.cyno-select-required]': 'required',
        '(change)': 'onChange()',
        '(focus)': '_onFocus()',
        '(blur)': '_onBlur()',
    },
    providers: [
        {provide: CynoFieldControl, useExisting: CynoSelectDirective},
        {provide: CYNO_OPTION_PARENT_COMPONENT, useExisting: CynoSelectDirective}
    ],
})
export class CynoSelectDirective extends _CynoSelectMixinBase implements
      OnInit, DoCheck, ControlValueAccessor, CanDisable, HasTabIndex, CynoFieldControl<any>, CanUpdateErrorState {

    @Input()
    get disabled(): boolean {
        if (this.ngControl && this.ngControl.disabled !== null) {
            return this.ngControl.disabled;
        }
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = value;

        if (this.focused) {
            this.focused = false;
        }
    }
    protected _disabled = false;

    focused: boolean = false;

    @Input() id: string;
    @Input() value: string;
    @Input() required: boolean = false;
    @Input() placeholder: string;
    @Input() multiple: boolean = false;
    @Input() tabIndex: number = 0;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        elementRef: ElementRef,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Self() @Optional() public ngControl: NgControl
    ) {
        super(elementRef, _defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    }
    _onChange: (value: any) => void = () => {};
    _onTouched = () => {};

    ngOnInit() {
        this.id = `${this.ngControl.name}`;
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    writeValue(value: any): void {
        if (value) {
            this._elementRef.nativeElement.value = value;
        }
    }

    registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    onChange(value: any): void {
        this._onChange(this._elementRef.nativeElement.value);
        this._onTouched();
    }

    _onFocus() {
        if (!this.disabled) {
            this._onTouched();
            this.focused = true;
        }
    }

    _onBlur() {
        this.focused = false;

        if (!this.disabled) {
            this._onTouched();
            this._changeDetectorRef.markForCheck();
        }
    }

    focus(): void {
        this._elementRef.nativeElement.focus();
    }

    onContainerClick() {
        this.focus();
    }
}
