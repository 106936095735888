<label class="control-label" *ngIf="inline" [attr.for]="control?.id" #label>
    <div class="control-label__inner">
        <ng-content select="cyno-label"></ng-content>
        &nbsp;
        <a href="#" title="Informatie" (click)="onTooltip($event)" *ngIf="hasIconInfo()">
            <svg class="svg-icon svg-icon--xs">
                <use href="assets/icons-ui.svg#informatie"></use>
            </svg>
        </a>
    </div>
    <ng-container *ngIf="showInfo">
        <ng-content select="cyno-info"></ng-content>
    </ng-container>
</label>
<div
    class="control-input"
    [ngClass]="{
        'control-input--full': !inline,
        'control-input--with-button': withButton,
        'control-input--dual-input': dualInput
    }"
>
    <ng-content></ng-content>
</div>
