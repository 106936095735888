import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[cynoInputType]',
})
export class CynoInputTypeDirective implements OnInit {

    @Input('cynoInputType')
    mask = '';

    input: HTMLInputElement;

    private readonly isNonNumericRexExp = /^\D$/m;
    private readonly isNonAlphaRexExp = /^[^a-zA-Z ]$/m;

    constructor(
        private el: ElementRef,
    ) {
        this.input = el.nativeElement;
    }

    public ngOnInit(): void {
        this.input.setAttribute('type', 'text');
        this.setCurrentInputMode();
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {

        if (!event.altKey
            && !event.ctrlKey
            && ((this.currentPositionIsNumeric && this.isNonNumericRexExp.test(event.key))
                || (!this.currentPositionIsNumeric && this.isNonAlphaRexExp.test(event.key)))
        ) {
            event.preventDefault();
        }
    }

    @HostListener('keyup')
    @HostListener('click')
    public updateCurrentType(): void {
        this.setCurrentInputMode();
    }

    private setCurrentInputMode(): void {
        if (this.currentPositionIsNumeric) {
            this.input.setAttribute('inputmode', 'numeric');
        } else {
            this.input.setAttribute('inputmode', null);
        }
    }

    private get currentPositionIsNumeric(): boolean {
        const cursorPos = this.input.selectionStart;
        return Array.from(this.mask)[cursorPos] === '9';
    }
}
