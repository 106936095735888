import { ComponentFactory, ComponentFactoryResolver, Directive, HostBinding, HostListener, Input, Type, ViewContainerRef } from '@angular/core';
import { TooltipComponent } from './tooltip.component';

/** @deprecated moved to libs folder */
@Directive({
    selector: '[insTooltip]',
})
export class TooltipDirective {

    private componentInstance;
    private animating = false;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) { }

    @Input('insTooltip') insTooltip: string;
    @Input('orientation') orientation: string = 'bottom';

    @HostBinding('style.position') position: string = 'relative';
    @HostBinding('style.cursor') cursor: string;

    @HostListener('click') click(event: Event): boolean {
        return this.animating || !this.insTooltip;
    }

    @HostListener('mouseenter') mouseover(event: Event) {
        if (this.insTooltip && this.cursor !== 'pointer') {
            this.cursor = 'pointer';
        }

        this.show();
    }

    @HostListener('mouseleave') mouseleave(event: Event) {
        this.hide();
    }

    private show(): void {
        if (this.animating || !this.insTooltip) {
            return;
        }

        const componentfactory = this.createComponentFactory();
        const componentInstance = this.viewContainerRef.createComponent(componentfactory);

        componentInstance.instance.content = this.insTooltip;
        componentInstance.instance.orientation = this.orientation;
        componentInstance.instance.visible$.next(true);

        this.componentInstance = componentInstance;
        this.insertComponent();
    }

    private hide(): void {
        if (this.animating || !this.insTooltip) {
            return;
        }

        this.componentInstance.instance.visible$.next(false);
        this.animating = true;

        setTimeout(() => {
            this.componentInstance.destroy();
            this.animating = false;
        }, 75);
    }

    private createComponentFactory(): ComponentFactory<TooltipComponent> {
        const component: Type<TooltipComponent> = TooltipComponent;
        const componentfactory: ComponentFactory<TooltipComponent> = this.componentFactoryResolver.resolveComponentFactory(component);

        return componentfactory;
    }

    private insertComponent(): void {
        const tooltipComponent = this.componentInstance.location.nativeElement;
        const sibling: HTMLElement = tooltipComponent.previousSibling;

        sibling.insertBefore(tooltipComponent, sibling.firstChild);
    }
}
