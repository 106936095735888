import { ChangeDetectorRef, Directive, ElementRef, forwardRef, Input, OnDestroy, Optional, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CynoLabelNewDirective } from './cyno-label-new.directive';

let nextId = 0;

/**
 * Allows to easily create radio buttons.
 *
 * Integrates with forms, so the value of a checked button is bound to the underlying form control
 * either in a reactive or template-driven way.
 */
@Directive({
    selector: '[cynoRadioNewGroup]',
    host: {role: 'radiogroup'},
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CynoRadioNewGroupDirective),
        multi: true,
    }],
})
export class CynoRadioNewGroupDirective implements ControlValueAccessor {

    get disabled() { return this._disabled; }
    set disabled(isDisabled: boolean) { this.setDisabledState(isDisabled); }
    private _radios: Set<CynoRadioNewDirective> = new Set<CynoRadioNewDirective>();
    private _value = null;
    private _disabled: boolean;

    /**
     * Name of the radio group applied to radio input elements.
     *
     * Will be applied to all radio input elements inside the group,
     * unless CynoRadioNew's specify names themselves.
     *
     * If not provided, will be generated in the `cyno-radio-new-xx` format.
     */
    @Input() name = `cyno-radio-new-${nextId++}`;

    onChange = (_: any) => {};
    onTouched = () => {};

    onRadioChange(radio: CynoRadioNewDirective) {
        this.writeValue(radio.value);
        this.onChange(radio.value);
    }

    onRadioValueUpdate() { this._updateRadiosValue(); }

    register(radio: CynoRadioNewDirective) { this._radios.add(radio); }

    registerOnChange(fn: (value: any) => any): void { this.onChange = fn; }

    registerOnTouched(fn: () => any): void { this.onTouched = fn; }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
        this._updateRadiosDisabled();
    }

    unregister(radio: CynoRadioNewDirective) { this._radios.delete(radio); }

    writeValue(value) {
        this._value = value;
        this._updateRadiosValue();
    }

    private _updateRadiosValue() { this._radios.forEach((radio) => radio.updateValue(this._value)); }
    private _updateRadiosDisabled() { this._radios.forEach((radio) => radio.updateDisabled()); }
}

/**
 * A directive that marks an input of type "radio" as a part of the CynoRadioNewGroup
 */
@Directive({
    selector: '[cynoRadioNew][type=radio]',
    host: {
        class: 'radio',
        '[checked]': 'checked',
        '[disabled]': 'disabled',
        '[name]': 'nameAttr',
        '(change)': 'onChange()',
        '(focus)': 'focused = true',
        '(blur)': 'focused = false',
    },
})
export class CynoRadioNewDirective implements OnDestroy {

    /**
     * The form control value when current radio button is checked.
     */
    @Input('value') set value(value: any) {
        this._value = value;
        const stringValue = value ? value.toString() : '';
        this._renderer.setProperty(this._element.nativeElement, 'value', stringValue);
        this._group.onRadioValueUpdate();
    }

    /**
     * If `true`, current radio button will be disabled.
     */
    @Input('disabled') set disabled(isDisabled: boolean) {
        this._disabled = isDisabled !== false;
        this.updateDisabled();
    }

    set focused(isFocused: boolean) {
        if (this._label) {
            this._label.focused = isFocused;
        }
        if (!isFocused) {
            this._group.onTouched();
        }
    }

    get checked() { return this._checked; }

    get disabled() { return this._group.disabled || this._disabled; }

    get value() { return this._value; }

    get nameAttr() { return this.name || this._group.name; }
    static ngAcceptInputType_disabled: boolean | '';

    private _checked: boolean;
    private _disabled: boolean;
    private _value: any = null;

    /**
     * The value for the 'name' property of the input element.
     *
     * All inputs of the radio group should have the same name. If not specified,
     * the name of the enclosing group is used.
     */
    @Input() name: string;

    constructor(
        private _group: CynoRadioNewGroupDirective,
        @Optional() private _label: CynoLabelNewDirective,
        private _renderer: Renderer2,
        private _element: ElementRef<HTMLInputElement>,
        private _cd: ChangeDetectorRef,
    ) {
        this._group.register(this);
        this.updateDisabled();
    }

    ngOnDestroy() { this._group.unregister(this); }

    onChange() { this._group.onRadioChange(this); }

    updateValue(value) {
        // label won't be updated, if it is inside the OnPush component when [ngModel] changes
        if (this.value !== value) {
        this._cd.markForCheck();
        }

        this._checked = this.value === value;

        if (this._label) {
            this._label.active = this._checked;
        }
    }

    updateDisabled() {
        if (this._label) {
            this._label.disabled = this.disabled;
        }
    }
}
