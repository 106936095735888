import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CynoPasswordInputComponent } from './cyno-password.component';
import { ListModule } from '@app/shared/ux/lists/list.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ListModule,
    ],
    declarations: [
        CynoPasswordInputComponent,
    ],
    exports: [
        CynoPasswordInputComponent,
    ],
    providers: [],
})
export class CynoPasswordModule {}
