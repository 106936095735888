import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { CynoFieldControl } from '../core/cyno-field-control';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';
import { ErrorStateMatcher } from '../core/error-options';
import { Observable, Subscription } from 'rxjs';
import { EventListenerService } from '@app/core/services/event-listener.service';

class CynoPostalCodeBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl
    ) {
    }
}

const _CynoPostalCodeMixinBase = mixinErrorState( CynoPostalCodeBase );

@Component( {
    selector: 'cyno-postcode',
    templateUrl: 'cyno-postcode.component.html',
    providers: [
        {
            provide: CynoFieldControl,
            useExisting: CynoPostcodeInputComponent,
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
} )
export class CynoPostcodeInputComponent extends _CynoPostalCodeMixinBase
    implements ControlValueAccessor, CynoFieldControl<string>, OnInit, DoCheck, OnDestroy, CanUpdateErrorState, AfterViewInit {

    protected _disabled = false;

    @Input() get value(): string | null {
        return this.postalCode.nativeElement.value.match( /[a-zA-Z0-9]/g ).join( '' );
    }

    set value( postalCode: string | null ) {
        this.stateChanges.next();
    }

    @Input() get placeholder(): string {
        return this._placeholder;
    }

    set placeholder( plh ) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input() get required(): boolean {
        return this._required;
    }

    set required( req ) {
        this._required = req;
        this.stateChanges.next();
    }

    @Input() get disabled(): boolean {
        return this._disabled;
    }

    set disabled( value: boolean ) {
        this._disabled = value;
        this.stateChanges.next();
    }

    private _placeholder: string;

    private _required = false;
    private subscriptions: Subscription = new Subscription();

    public keyDown$: Observable<KeyboardEvent>;
    public keyUp$: Observable<KeyboardEvent>;

    public inputmode: string;
    public focused = false;
    public errorState = false;
    public controlType = 'cyno-postalcode-input';
    public id = this.controlType;


    @ViewChild( 'postalCode', { static: true } ) postalCode: ElementRef;

    @Output() readonly changed: EventEmitter<string> = new EventEmitter<string>();

    public constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() public _parentForm: NgForm,
        @Optional() public _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
        private _elementRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef,
        private eventListenerService: EventListenerService,
    ) {
        super( _defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl );

        if ( this.ngControl !== null ) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        this.stateChanges.next();
        this.checkCurrentInputmode();
    }

    public ngAfterViewInit(): void {
        this.keyDown$ = this.eventListenerService.targetEvent<KeyboardEvent>( this.postalCode.nativeElement, 'keydown' );
        this.keyUp$ = this.eventListenerService.targetEvent<KeyboardEvent>( this.postalCode.nativeElement, 'keyup' );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.stateChanges.complete();
    }

    public ngDoCheck(): void {
        if ( this.ngControl ) {
            this.updateErrorState();
        }
    }

    public onChange = ( postalCode: string ): void => {};

    public onTouched = (): void => {};

    public registerOnChange( fn: ( postalCode: string ) => void ): void {
        this.onChange = ( postalCode: string ) => {
            fn( (postalCode || '').replace( /[\s-]+/, '' ).toUpperCase() );
        };
    }

    public setDisabledState( isDisabled: boolean ): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
        this.stateChanges.next();
    }

    public writeValue( postalCode: string ): void {
        this.postalCode.nativeElement.value = postalCode;
        this.onTouched();
        this.stateChanges.next();
    }

    public registerOnTouched( fn: () => void ): void {
        this.onTouched = fn;
    }

    public change( $event ): void {
        this.checkCurrentInputmode();
        this.onChange( $event.target.value );
        this.stateChanges.next();
    }

    public focus(): void {
        this._elementRef.nativeElement.focus();
    }

    public onContainerClick( event: MouseEvent ): void {
        this.focus();
    }

    public onFocus(): void {
        this.focused = true;
    }

    public onBlur(): void {
        const charRegex = new RegExp( /[a-zA-Z]/g );
        const numberRegex = new RegExp( /[0-9]/g );
        const postalCode = this.postalCode.nativeElement.value;

        if ( postalCode.length > 5 && postalCode.length < 8 ) {
            let numbers = postalCode.match(numberRegex) || '';
            if (numbers && numbers !== '') {
                numbers = numbers.join( '' );
            }

            let characters = postalCode.match( charRegex ) || '';
            if (characters && characters !== '') {
                characters = characters.join( '' ).toUpperCase();
            }

            this.postalCode.nativeElement.value = `${ numbers } ${ characters }`;
        }

        this.focused = false;

        if ( !this.focused ) {
            this.onTouched();
        }
        this.stateChanges.next();
    }

    private checkCurrentInputmode(): void {
        this.inputmode = this.postalCode.nativeElement.value.length >= 4 ? '' : 'numeric';
    }
}
