<div>
    <div class="control-input__hours">
        <input #hours type="tel" class="form-control" maxlength="2" name="hours" placeholder="00"
            (input)="onInputChange()"
            (keydown.backspace)="backspace($event)" (focus)="_onFocus()" (blur)="_onBlur()"
            [disabled]="disabled"
            [id]="id">
    </div>
    <div class="control-input__time">
        <span>:</span>
    </div>
    <div class="control-input__minutes">
        <input #minutes type="tel" class="form-control" maxlength="2" name="minutes" placeholder="00"
            (input)="onInputChange()"
            (keydown.backspace)="backspace($event)" (focus)="_onFocus()" (blur)="_onBlur()"
            [disabled]="disabled">
    </div>
</div>