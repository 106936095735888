<input class="form-control form-control--uppercase" name="postalcode" [id]="id" e2e="postalcode"
       #postalCode
       type="text"
       [attr.inputmode]="inputmode"
       cynoInput
       [placeholder]="placeholder"
       [disabled]="disabled"
       (focus)="onFocus()"
       (blur)="onBlur()"
       (input)="change($event)"
       (change)="change($event)"
       minlength="6"
       maxlength="7"
       pattern="^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$"
       autocomplete="postal-code"
/>
