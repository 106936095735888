import { Directive, Input, Self, Optional, OnDestroy, DoCheck, Inject, ElementRef, AfterContentInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl, NgForm, FormGroupDirective } from '@angular/forms';

import { Subject } from 'rxjs';

import { CynoFieldControl } from '../core/cyno-field-control';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';
import { ErrorStateMatcher } from '../core/error-options';
import { getSupportedInputTypes } from '../core/features';

export class InputBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl
    ) {
    }
}

export const _InputMixinBase = mixinErrorState(InputBase);

@Directive({
    selector: '[cynoInput]',
    exportAs: 'cynoInput',
    providers: [{provide: CynoFieldControl, useExisting: CynoInputDirective}],
    host: {
        class: 'form-control',
        '[attr.id]': 'id',
        '[attr.placeholder]': 'placeholder',
        '[disabled]': 'disabled',
        '[required]': 'required',
        '[readonly]': 'readonly',
        '(blur)': '_focusChanged(false)',
        '(focus)': '_focusChanged(true)',
        '(input)': '_onInput()',
    },
})

export class CynoInputDirective
    extends _InputMixinBase
    implements CynoFieldControl<any>, OnDestroy, DoCheck, CanUpdateErrorState, AfterContentInit {

    protected _previousNativeValue: any;

    @Input() id: string | number = '';

    @Input() get disabled(): boolean {
        if (this.ngControl && this.ngControl.disabled !== null) {
            return this.ngControl.disabled;
        }
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = value;

        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }
    }

    protected _disabled = false;

    @Input() get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = value;
    }

    protected _required = false;

    @Input() get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value || 'text';
        // this._validateType();

        //   When using Angular inputs, developers are no longer able to set the properties on the native
        //   input element. To ensure that bindings for `type` work, we need to sync the setter
        //   with the native property. Textarea elements don't support the type property or attribute.
        if (!this._isTextarea() && getSupportedInputTypes().has(this._type)) {
            this._elementRef.nativeElement.type = this._type;
        }
    }

    protected _type = 'text';

    @Input() get value(): string {
        return this._inputValueAccessor.value;
    }

    set value(value: string) {
        if (value !== this.value) {
            this._inputValueAccessor.value = value;
            this._inputValueAccessor.touched = true;
            this.stateChanges.next();
        }
    }

    @Input() get readonly(): boolean {
        return this._readonly;
    }

    set readonly(value: boolean) {
        this._readonly = value;
    }

    @Input() get placeholder(): string {
        return this._placeholder;
    }

    set placeholder(value: string) {
        this._placeholder = value;
    }

    protected _dirtyCheckNativeValue() {
        const newValue = this.value;

        if (this._previousNativeValue !== newValue) {
            this._previousNativeValue = newValue;
            this.stateChanges.next();
        }
    }

    protected _isTextarea() {
        return this._elementRef.nativeElement.nodeName.toLowerCase() === 'textarea';
    }
    private _inputValueAccessor: { value: any, touched: boolean };

    private _readonly = false;

    private _placeholder: string;
    public focused: boolean = false;
    readonly stateChanges: Subject<void> = new Subject<void>();

    constructor(
        protected _elementRef: ElementRef,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Self() @Inject(NG_VALUE_ACCESSOR) inputValueAccessor: any,
        _defaultErrorStateMatcher: ErrorStateMatcher,
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
        const element = this._elementRef.nativeElement;
        this._inputValueAccessor = inputValueAccessor || element;
        this._previousNativeValue = this.value;
        this.id = this.id;
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.updateErrorState();
        }

        this._dirtyCheckNativeValue();
    }

    ngAfterContentInit() {
        if (!this.id) {
            this.id = this.ngControl.name;
        }
    }

    focus(): void {
        this._elementRef.nativeElement.focus();
    }

    _focusChanged(isFocused: boolean) {
        if (isFocused !== this.focused && !this.readonly) {
            this.focused = isFocused;
            this.stateChanges.next();
        }
    }

    _onInput() {
        this.stateChanges.next();
        this._inputValueAccessor.touched = true;
    }

    onContainerClick() {
        if (!this.focused) {
            this.focus();
        }
    }
}
