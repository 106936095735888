import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { CountryInterface } from '@app/core/interfaces';
import { CountryService } from '@app/store/my-zone/country/country.service';
import { Subject, Subscription } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { ErrorStateMatcher } from '../core/error-options';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';

class CynoPhoneBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl
    ) {
    }
}

const _CynoPhoneMixinBase = mixinErrorState(CynoPhoneBase);

class CynoPhone {
    constructor() {
    }
}

@Component({
    selector: 'cyno-phone',
    templateUrl: './cyno-phone.component.html',
    styles: [`
        :host(.cyno-phone) {
            display: flex;
            width: calc(100% - 27px);
        }

        select {
            flex: 1;
        }

        input {
            flex: 4;
        }
    `],
    host: {'class': 'cyno-phone'},
    providers: [{provide: CynoFieldControl, useExisting: CynoPhoneInputComponent}],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CynoPhoneInputComponent extends _CynoPhoneMixinBase
    implements ControlValueAccessor, CynoFieldControl<CynoPhone>, CanUpdateErrorState, DoCheck, OnInit, OnDestroy {

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get required() {
        return this._required;
    }

    set required(req) {
        this._required = req;
        this.stateChanges.next();
    }

    @Input()
    get disabled() {
        return this._disabled;
    }

    set disabled(dis: boolean) {
        this._disabled = dis;
        this.stateChanges.next();
    }

    protected _disabled: boolean = true;

    static nextId = 0;

    private _placeholder: string;
    private _required = false;
    private subscriptions = new Subscription();
    @ViewChild('country_number', {static: true}) country_number: ElementRef;
    @ViewChild('phone_number', {static: true}) phone_number: ElementRef;

    public id: string = `cyno-phone_${CynoPhoneInputComponent.nextId++}`;

    public value: {
        country_number: string,
        phone_number: string
    } = {
        country_number: null,
        phone_number: null,
    };

    public focused: boolean = false;
    public countries: Set<CountryInterface | { telephone_country_code: string, country_description: string }> = new Set();

    public stateChanges: Subject<void> = new Subject();

    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        private _changeDetectorRef: ChangeDetectorRef,
        private countryService: CountryService,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        const countrySubscription = this.countryService.getCountries().subscribe(
            (countries: CountryInterface[]) => {
                this.handleCountrySuccess(countries);
            },
            () => {
                this.handleCountryError();
            },
        );
        this.subscriptions.add(countrySubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public writeValue(value: { country_number: string, phone_number: string }): void {
    }

    public registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    public onContainerClick(): void {
    }

    public onCountryNumberChange(event: Event): void {
        this.value.country_number = (event.target['value']) ? event.target['value'].toString() : null;

        if (this.value.country_number && this.value.phone_number) {
            this._onChange(this.value);
        } else {
            this._onChange(null);
        }
    }

    public onPhoneNumberChange(event: Event): void {
        this.value.phone_number = (this._validationNumber(event.target['value'])) ? event.target['value'] : null;

        if (this.value.country_number && this.value.phone_number) {
            this._onChange(this.value);
        } else {
            this._onChange(null);
        }
    }

    public onFocus(): void {
        if (this.phone_number.nativeElement.value) {
            this._onTouched();
        }
    }

    public onBlur(): void {
        if (this.phone_number.nativeElement.value) {
            this._onTouched();
        }
    }

    private _onChange: (value: { country_number: string, phone_number: string }) => void = () => {
    }
    private _onTouched = () => {
    }

    private handleCountrySuccess(countries: CountryInterface[]): void {
        countries.forEach(
            (country: CountryInterface) => {
                this.countries.add(country);
            },
        );
        this.value.country_number = countries[0].telephone_country_code.toString();
        this.disabled = false;
        this._changeDetectorRef.detectChanges();
    }

    private handleCountryError(): void {
        this.disabled = false;
        this.value.country_number = '31';
        this.countries.add({ telephone_country_code: this.value.country_number, country_description: 'Nederland' });
        this._changeDetectorRef.detectChanges();
    }

    private _validationNumber(phone_number: string): boolean {
        const pattern = /^-?\d*\.?\d*$/;
        let valid = true;

        if (phone_number.length < 9) {
            valid = false;
        }

        if (!pattern.test(phone_number)) {
            valid = false;
        }

        return valid;
    }
}
