import {
    AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Component, DoCheck, ElementRef, Input, Optional, Self, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { ErrorStateMatcher } from '../core/error-options';
import { mixinErrorState } from '../core/error-state';

class CynoCheckboxBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
    ) {}
}

const _CynoCheckboxMixinBase = mixinErrorState(CynoCheckboxBase);

class CynoCheckbox {}

@Component({
    // otherwise multiple files must be updated and a chain reaction would occur
    // tslint:disable-next-line: component-selector
    selector: 'cyno-checkbox',
    template: `<input type="checkbox" [name]="ngControl.name" [value]="value" [id]="id" [checked]="checked"
    (change)="onInputChange($event)" (click)="onInputClick($event)">
    <label [for]="id"><ng-content></ng-content></label>`,
    providers: [{ provide: CynoFieldControl, useExisting: CynoCheckboxInputComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CynoCheckboxInputComponent
    extends _CynoCheckboxMixinBase
    implements ControlValueAccessor, CynoFieldControl<CynoCheckbox>, AfterContentInit, DoCheck {

    @Input() get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input() get required(): boolean {
        return this._required;
    }
    set required(req) {
        this._required = req;
        this.stateChanges.next();
    }
    @Input() get disabled(): boolean {
        return this._disabled;
    }
    set disabled(isDisabled: boolean) {
        this._disabled = isDisabled;
        this.stateChanges.next();
    }

    @Input() get checked(): boolean {
        return this._checked;
    }

    set checked(isChecked: boolean) {
        this._checked = isChecked;
        this.stateChanges.next();
    }
    protected _disabled: boolean = false;

    static nextId = 0;
    @ViewChild('initials') initials: ElementRef;

    public id: string;
    public focused: boolean = false;

    @Input() value: string | number | boolean;

    // otherwise multiple files must be updated and a chain reaction would occur
    // tslint:disable-next-line: rxjs-finnish
    public stateChanges: Subject<void> = new Subject();
    private _checked: boolean = false;
    private _currentValue: string | number | boolean;
    private _placeholder: string;
    private _required = false;

    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngAfterContentInit(): void  {
        this.id = `${this.ngControl.name}-${this.value}`;

        if (this.value === this._currentValue) {
            this.checked = true;
        }
    }

    public ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public writeValue(currentValue: string | number | boolean): void {
        this.checked = this.value === currentValue;
        this.changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    public onInputChange(event): void  {
        this.checked = !this.checked;

        this._onChange(this.checked ? this.value : this._currentValue);

        this.changeDetectorRef.markForCheck();
        this._onTouched();
    }

    public onInputClick(event: Event): void {
        event.stopPropagation();
    }

    // tslint:disable-next-line: no-empty
    public onContainerClick(): void {}

    // tslint:disable-next-line: no-empty
    private _onChange: (value: any) => void = () => {};
    // tslint:disable-next-line: no-empty
    private _onTouched = () => {};
}
