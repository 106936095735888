import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CynoInputFormatDirective } from '@app/shared/cyno-form/cyno-directives/cyno-input-format.directive';
import { CynoInputTypeDirective } from '@app/shared/cyno-form/cyno-directives/cyno-input-type.directive';
import { TooltipModule } from '../tooltip/tooltip.module';
import { ErrorStateMatcher } from './core/error-options';
import { CynoAlertComponent } from './cyno-alert/cyno-alert.component';
import { CynoBirthdateInputComponent } from './cyno-birthdate/cyno-birthdate.component';
import { CynoCheckboxInputComponent } from './cyno-checkbox/cyno-checkbox.component';
import { CynoDateInputComponent } from './cyno-date/cyno-date.component';
import { CynoFormatDirective } from './cyno-directives/cyno-format.directive';
import { CynoInfoDirective } from './cyno-directives/cyno-info.directive';
import { CynoInputDirective } from './cyno-directives/cyno-input.directive';
import { CynoLabelDirective } from './cyno-directives/cyno-label.directive';
import { CynoOptionDirective } from './cyno-directives/cyno-option.directive';
import { CynoPhoneDirective } from './cyno-directives/cyno-phone.directive';
import { CynoSelectDirective } from './cyno-directives/cyno-select.directive';
import { CynoErrorComponent } from './cyno-error/cyno-error.component';
import { CynoFieldComponent } from './cyno-field/cyno-field.component';
import { CynoIbanInputComponent } from './cyno-iban/cyno-iban.component';
import { CynoInitialsInputComponent } from './cyno-initials/cyno-initials.component';
import { CynoPasswordModule } from './cyno-password/cyno-password.module';
import { CynoPhoneInputComponent } from './cyno-phone/cyno-phone.component';
import { CynoPostcodeInputComponent } from './cyno-postcode/cyno-postcode.component';
import { CynoLabelNewDirective } from './cyno-radio/cyno-label-new.directive';
import { CynoRadioNewDirective, CynoRadioNewGroupDirective } from './cyno-radio/cyno-radio-new.directive';
import { CynoRadioInputComponent } from './cyno-radio/cyno-radio.component';
import { CynoSelectModule } from './cyno-select/cyno-select.module';
import { CynoTimeInputComponent } from './cyno-time/cyno-time.component';
import { SaveButtonComponent } from './save-button/save-button.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CynoPasswordModule,
        CynoSelectModule,
        TooltipModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        CynoSelectDirective,
        CynoOptionDirective,
        CynoInputDirective,
        CynoLabelDirective,
        CynoInfoDirective,
        CynoFormatDirective,
        CynoErrorComponent,
        CynoFieldComponent,
        CynoPasswordModule,
        CynoDateInputComponent,
        CynoInitialsInputComponent,
        CynoPhoneInputComponent,
        CynoIbanInputComponent,
        CynoRadioInputComponent,
        CynoLabelNewDirective,
        CynoRadioNewDirective,
        CynoRadioNewGroupDirective,
        CynoCheckboxInputComponent,
        CynoTimeInputComponent,
        CynoAlertComponent,
        CynoBirthdateInputComponent,
        CynoPostcodeInputComponent,
        CynoSelectModule,
        SaveButtonComponent,
        CynoPhoneDirective,
        CynoInputTypeDirective,
        CynoInputFormatDirective,
    ],
    declarations: [
        CynoFieldComponent,
        CynoSelectDirective,
        CynoOptionDirective,
        CynoInputDirective,
        CynoLabelDirective,
        CynoInfoDirective,
        CynoErrorComponent,
        CynoFormatDirective,
        CynoDateInputComponent,
        CynoInitialsInputComponent,
        CynoPhoneInputComponent,
        CynoIbanInputComponent,
        CynoRadioInputComponent,
        CynoLabelNewDirective,
        CynoRadioNewDirective,
        CynoRadioNewGroupDirective,
        CynoCheckboxInputComponent,
        CynoTimeInputComponent,
        CynoAlertComponent,
        CynoBirthdateInputComponent,
        CynoPostcodeInputComponent,
        SaveButtonComponent,
        CynoPhoneDirective,
        CynoInputTypeDirective,
        CynoInputFormatDirective,
    ],
    providers: [ErrorStateMatcher],
})
export class CynoFormModule {}
