import { ChangeDetectorRef, Component, DoCheck, ElementRef, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';

import { CynoFieldControl } from '../core/cyno-field-control';
import { CanDisable, mixinDisabled } from '../core/disabled';
import { HasTabIndex, mixinTabIndex } from '../core/tabindex';
import { ErrorStateMatcher } from '../core/error-options';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';
import { CYNO_OPTION_PARENT_COMPONENT } from '../cyno-directives/cyno-option.directive';

export class CynoSelectBase {
    constructor(public _elementRef: ElementRef,
                public _defaultErrorStateMatcher: ErrorStateMatcher,
                public _parentForm: NgForm,
                public _parentFormGroup: FormGroupDirective,
                public ngControl: NgControl) {}
}
export const _CynoSelectMixinBase = mixinTabIndex(mixinDisabled(mixinErrorState(CynoSelectBase)));

@Component({
    selector: 'cyno-select',
    exportAs: 'cynoSelect',
    templateUrl: './cyno-select.component.html',
    styleUrls: ['./cyno-select.component.scss'],
    providers: [
        {provide: CynoFieldControl, useExisting: CynoSelect},
        {provide: CYNO_OPTION_PARENT_COMPONENT, useExisting: CynoSelect}
    ],
})
export class CynoSelect extends _CynoSelectMixinBase implements
      OnInit, DoCheck, ControlValueAccessor, CanDisable, HasTabIndex, CynoFieldControl<any>, CanUpdateErrorState {

    @Input()
    get disabled(): boolean {
        if (this.ngControl && this.ngControl.disabled !== null) {
            return this.ngControl.disabled;
        }
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = value;

        if (this.focused) {
            this.focused = false;
        }
    }
    protected _disabled = false;

    focused: boolean = false;

    @Input() id: string;
    @Input() value: string;
    @Input() required: boolean = false;
    @Input() placeholder: string;
    @Input() multiple: boolean = false;
    @Input() tabIndex: number = 0;

    @ViewChild('select', { read: ElementRef, static: true }) selectRef: ElementRef;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        elementRef: ElementRef,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Self() @Optional() public ngControl: NgControl
    ) {
        super(elementRef, _defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }
    _onChange: (value: any) => void = () => {};
    _onTouched = () => {};

    ngOnInit(): void {
        this.id = `${this.ngControl.name}`;
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    writeValue(value: any): void {
        this.value = value || '';
    }

    registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    onChange(event: Event): void {
        this._onChange( event.target['value']);
        this._onTouched();
    }

    _onFocus(): void {
        if (!this.disabled) {
            this.focused = true;
        }
    }

    _onBlur(): void {
        this.focused = false;

        if (!this.disabled) {
            this._onTouched();
            this._changeDetectorRef.markForCheck();
        }
    }

    focus(): void {
        this.selectRef.nativeElement.focus();
    }

    onContainerClick(): void {
        this.focus();
    }
}
