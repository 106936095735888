import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

/** @deprecated moved to libs folder */
@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        TooltipDirective,
    ],
    declarations: [
        TooltipDirective,
        TooltipComponent,
    ],
})
export class TooltipModule { }
