import { Directive } from '@angular/core';

@Directive({
    selector: '[cynoLabelNew]',
    host:
        {'[class.active]': 'active', '[class.disabled]': 'disabled', '[class.focus]': 'focused'}
})
export class CynoLabelNewDirective {
    active: boolean;
    disabled: boolean;
    focused: boolean;
}
