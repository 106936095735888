import {
    Component, Input, OnDestroy, Optional, Self, ElementRef,
    ChangeDetectorRef, OnInit, DoCheck, ChangeDetectionStrategy, ViewChild
} from '@angular/core';
import { NgControl, ControlValueAccessor, FormGroupDirective, NgForm } from '@angular/forms';

import { Subject } from 'rxjs';

import { CynoFieldControl } from '../core/cyno-field-control';
import { mixinErrorState, CanUpdateErrorState } from '../core/error-state';
import { ErrorStateMatcher } from '../core/error-options';

class CynoTimeBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl
    ) {}
}

const _CynoTimeMixinBase = mixinErrorState(CynoTimeBase);

class CynoTime {
    constructor(public hours: number= null, public minutes: number= null) {}
}

@Component({
    selector: 'cyno-time',
    templateUrl: './cyno-time.component.html',
    styleUrls: ['./cyno-time.component.scss'],
    providers: [{ provide: CynoFieldControl, useExisting: CynoTimeInputComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CynoTimeInputComponent extends _CynoTimeMixinBase
    implements ControlValueAccessor, CynoFieldControl<CynoTime>, OnInit, DoCheck, OnDestroy, CanUpdateErrorState {

    @Input()
    get value(): CynoTime | null {
        return null;
    }
    set value(date: CynoTime | null) {
        this.stateChanges.next();
    }

    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = req;
        this.stateChanges.next();
    }

    @Input()
    get disabled() {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = value;
        this.stateChanges.next();
    }
    protected _disabled: boolean = false;

    static nextId = 0;
    private _placeholder: string;
    private _required = false;

    readonly stateChanges: Subject<void> = new Subject<void>();
    focused: boolean = false;
    errorState: boolean = false;
    controlType: string = 'cyno-date-input';

    @ViewChild('hours', { read: ElementRef, static: true }) hoursRef: ElementRef;
    @ViewChild('minutes', { read: ElementRef, static: true }) minutesRef: ElementRef;

    id = `cyno-time-input-${CynoTimeInputComponent.nextId++}`;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
        private _elementRef: ElementRef
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        this.stateChanges.next();
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    focus(): void {
        this._elementRef.nativeElement.focus();
    }

    writeValue(value: any): void {}

    registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
        this.stateChanges.next();
    }

    onContainerClick(event: MouseEvent): void {
        this.focus();
    }

    onInputChange(): void {
        const hours: number = this.hoursRef.nativeElement.value || null;
        const minutes: number = this.minutesRef.nativeElement.value || null;
        const minutes_str = (minutes) ? minutes.toString() : '';
        const hours_str = (hours) ? hours.toString() : '';

        if (hours_str.length > 1) {
            this.focusElement('minutes');
        }

        if (minutes_str.length > 0 && minutes < 60 && hours_str.length > 0 && hours < 24) {
            this.setValue(this.formatTime(minutes, hours));
        } else {
            this.setValue(null);
        }
    }

    formatTime(minutes: number, hours: number): string {
        let _minutes: string = minutes.toString();
        let _hours: string = hours.toString();

        if (_minutes.length < 2) {
            _minutes = `0${minutes}`;
        }

        if (_hours.length < 2) {
            _hours = `0${_hours}`;
        }

        return `${_hours}:${_minutes}`;
    }

    setValue(value) {
        if (value === null) {
            this._onChange(null);
        } else {
            this._onTouched();
            this._onChange(value);
        }
    }

    focusElement(name: string): void {
        switch (name) {
        case 'hours':
            this.hoursRef.nativeElement.focus();
            break;
        case 'minutes':
            this.minutesRef.nativeElement.focus();
            break;
        }
    }

    backspace(event: any): void {
        this._onChange(null);

        if (event.target.value === '') {
            if (event.target.name === 'minutes') {
                this.focusElement('hours');
            }
        }
    }

    public _onFocus(): void {
        this.focused = true;
    }

    public _onBlur(): void {
        this.focused = false;
        setTimeout(() => {
            if (!this.focused) {
                this._onTouched();
            }
        }, 50);
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
    }
    private _onChange: (value: any) => void = () => {};
    private _onTouched = () => {};
}
