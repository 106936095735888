<div class="cyno-select-wrapper" [ngClass]="{'cyno-select-wrapper--disabled': disabled}">
    <select #select
        [id]="id"
        [disabled]='disabled'
        (focus)="_onFocus()"
        (blur)="_onBlur()"
        (change)="onChange($event)"
        [value]="value"
        class="form-control form-control--dropdown">
        <ng-content></ng-content>
    </select>
</div>
