import { ChangeDetectionStrategy, Component, DoCheck, ElementRef, Input, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';

import { Subject } from 'rxjs';

import { CynoFieldControl } from '../core/cyno-field-control';
import { ErrorStateMatcher } from '../core/error-options';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';

class CynoInitialsBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl
    ) {}
}

const _CynoInitialsMixinBase = mixinErrorState(CynoInitialsBase);

class CynoInitials {
    constructor() {}
}

@Component({
    selector: 'cyno-initials',
    template: `
        <input #initials class="form-control" type="text"
            [id]="id" [disabled]="disabled" [required]="required" autocomplete="initials" e2e="initials" autofill="off"
            (keyup)="onInputChange($event)" (focus)="onFocus()" (blur)="onBlur()" maxlength="10">
    `,
    styles: [`
        .form-control {
            text-transform: uppercase;
        }
    `],
    providers: [{ provide: CynoFieldControl, useExisting: CynoInitialsInputComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CynoInitialsInputComponent extends _CynoInitialsMixinBase
    implements ControlValueAccessor, CynoFieldControl<CynoInitials>, CanUpdateErrorState, DoCheck {

    @Input() get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input() get required() {
        return this._required;
    }
    set required(req) {
        this._required = req;
        this.stateChanges.next();
    }
    @Input() get disabled() {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = value;
        this.stateChanges.next();
    }
    protected _disabled: boolean = false;

    static nextId = 0;
    private _placeholder: string;
    private _required = false;
    @ViewChild('initials', { static: true }) initials: ElementRef;

    public id: string = `cyno-initials-${CynoInitialsInputComponent.nextId++}`;
    public value: string;
    public focused: boolean = false;

    public stateChanges: Subject<void> = new Subject();

    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public writeValue(value: string): void {
        if (value) {
            this.initials.nativeElement.value = this._transform(value);
        }
    }

    public registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    public onContainerClick(): void {
        //
    }

    public onInputChange(event: any): void {
        const isBackspace = (event.keyCode === 8);

        const value = this._transform(this.initials.nativeElement.value, isBackspace);
        this.initials.nativeElement.value = value;

        this._onChange(value);

         // WINS-2648 Fixed het tonen van cyno-error. Moet ook nog voor cyno-input
         this._onTouched();
    }

    public onFocus(): void {
        if (this.initials.nativeElement.value) {
            this._onTouched();
        }
    }

    public onBlur(): void {
        this._onTouched();
    }

    private _onChange: (value: any) => void = () => {};
    private _onTouched = () => {};

    private _transform(value: string, isBackspace: boolean = false) {
        value = (value || '').toUpperCase();
        value = value.replace(/([^a-zA-Z])/g, '').slice(0, 5);

        const arrValue = value.split('');

        if (isBackspace) {
            arrValue.pop();
        }

        return arrValue.reduce(
            (previousValue: string, currentValue: string) => {
                return `${previousValue}${currentValue}.`;
            },
            ''
        );
    }
}
