import { Component, HostBinding, Input } from '@angular/core';
import { ErrorInterface, NotificationInterface } from 'outshared-lib';

@Component({
    selector: 'cyno-alert',
    templateUrl: './cyno-alert.component.html',
    styleUrls: ['./cyno-alert.component.scss'],
    exportAs: 'alert',
})
export class CynoAlertComponent {
    /** @deprecated */
    @Input() meldingen: NotificationInterface[] = [];
    // TODO : Check all instances and if translated, switch to notifications input
    @Input() notifications: NotificationInterface[] = [];
    /** @deprecated - Temporary solution until web ui library has this component */
    @Input() errors: ErrorInterface[] = [];
    @Input() customClass: string;

    @HostBinding('class') class: string = 'cyno-alert';
}
