import { Directive, ElementRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: '[cynoPhone]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CynoPhoneDirective,
            multi: true
        }
    ]
})
export class CynoPhoneDirective implements ControlValueAccessor {

    public constructor(private element: ElementRef) {}

    @HostListener('blur', [ '$event.target.value' ]) input(value: string) {
        const _value = (value) ? value.replace(/[\s\-]+/g, '') : '';
        this.onChange(_value);
        this.onTouched(_value);
    }

    public writeValue(value: string): void {
        // Even een hack voor een telelfoonnummer naar de backend te sturen
        // dit stuk is voor het zetten van de value
        // WINS-2946 - SF Telefoonnummer invoer / validatie naar zelfde niveau brengen als huidige site
        const input = this.element.nativeElement;
        input.value = value;
        input.dispatchEvent(new Event('input'));

        this.onChange(value);
        this.onTouched(value);
    }
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }
    private onChange: any = () => {};
    private onTouched: any = () => {};
}
