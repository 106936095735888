<div
    class="alert alert-error"
    [ngClass]="customClass"
    *ngIf="meldingen.length > 0 || notifications.length > 0 || errors?.length > 0"
>
    <ul>
        <li *ngFor="let melding of meldingen" [ngSwitch]="melding.soort">
            <svg class="svg-icon svg-icon--xs svg-icon--dark">
                <use href="assets/icons-ui.svg#cross-closed"></use>
            </svg>
            <span [innerHTML]="melding.boodschap_inhoud"></span>
        </li>
        <li *ngFor="let notification of notifications">
            <svg class="svg-icon svg-icon--xs svg-icon--dark">
                <use href="assets/icons-ui.svg#cross-closed"></use>
            </svg>
            <span [innerHTML]="notification.message_content"></span>
        </li>
        <li *ngFor="let error of errors">
            <svg class="svg-icon svg-icon--xs svg-icon--dark">
                <use href="assets/icons-ui.svg#cross-closed"></use>
            </svg>
            <span [innerHTML]="error.message"></span>
        </li>
    </ul>
</div>
