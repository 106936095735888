<select
    #country_number
    (blur)="onBlur()"
    (change)="onCountryNumberChange($event)"
    (focus)="onFocus()"
    [disabled]="disabled"
    [required]="required"
    class="form-control"
>
    <option *ngFor="let country of countries" value="{{ country.telephone_country_code }}">
        +{{ country.telephone_country_code }} ({{ country.country_description }})
    </option>
</select>
<input
    #phone_number
    (blur)="onBlur()"
    (focus)="onFocus()"
    (keyup)="onPhoneNumberChange($event)"
    [disabled]="disabled"
    [id]="id"
    [placeholder]="placeholder"
    [required]="required"
    class="form-control"
    type="number"
>
